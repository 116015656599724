<template lang="pug">
    .pa-10
        PageTitle(:label="id ? `Editar Cargo - #${id}` : 'Novo Cargo'")
            
        v-form(v-model="valid", ref="roleform")
            v-row
                v-col(cols="12", md="6", lg="4")
                    v-text-field(
                    outlined,
                    v-model="role.name",
                    :rules="rules.name",
                    :counter="100",
                    label="Nome",
                    required
                    )
            v-list(
                flat
                subheader
                three-line
                v-if="permissions.length > 0"
            )
                <v-subheader>Permissões</v-subheader>

                v-list-item-group(
                    multiple
                    active-class=""
                )
                    v-list-item(v-for="permission in permissions" :key="permission.id")
                        <template v-slot:default="{ active }">
                            v-list-item-action
                                v-checkbox(:input-value="active" v-model="role.permissions" multiple :value="permission.id")
                               
                            v-list-item-content
                                v-list-item-title(v-text="permission.name")
                                v-list-item-subtitle(v-text='permission.slug')
                        </template>
                
            v-btn(color="success", x-large, @click="saveRole", :disabled="!valid") Salvar
                v-icon(right) mdi-content-save
</template>

<script>
export default {
  name: "FormRoles",
  components: {
    PageTitle: () => import("@/components/PageTitle")
  },
  data: function() {
    return {
      valid: false,
      id: null,
      permissions: [],
      role: {
        id: null,
        name: "",
        permissions: [],
        permissions_id: [],
      },
      rules: {
        name: [
          v => !!v || v != "" || "Nome é obrigatório",
          v => v?.length >= 2 || "O Nome de conter mais de 2 caracteres"
        ],
        lastname: [v => !!v || v != "" || "Sobrenome é obrigatório"],
        login: [v => !!v || v != "" || "Login é obrigatório"],
        email: [v => !!v || v != "" || "Email é obrigatório"],
        password: [v => !!v || v != "" || "Senha é obrigatória"]
      } // rules
    };
  },

  mounted: function() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    this.loadData();
  },

  watch: {
    // 'permissions_selected' : function() {
    //     if(this.permissions_selected == null) {
    //         this.role.permissions = [];
    //     }else{
    //         this.role.permissions = this.permissions_selected.map(role => role.id)
    //     }
    // },
  },

  methods: {
    loadData: async function() {
      if ( this.id && this.acl('role.edit') ) {
        let role = await this.api.get(`role/${this.id}`);
        this.role = role.data;
        this.role.permissions = role.data.permissions.map(perm => perm.id)
      }      

      if ( this.acl( (this.id) ? 'role.edit' : 'role.add' ) ) {
        let permissions = await this.api.get(`permissions`, {all:true});
        this.permissions = permissions.data;
      }
    },

    saveRole: function() {
      let self = this;

      if (this.valid) {
        if (!this.id) {
          // New
          this.api.post("role", this.role).then(function(res) {
            if (res) {
              self.$root.toast.show({ message: "Cargo criado com sucesso!" });
              self.$refs.roleform.reset();
              self.valid = false;
            }
          });
        } else {
          // update

          this.api.put(`role/${this.id}`, this.role).then(function(res) {
            if (res) {
              self.$root.toast.show({
                message: "Cargo editado com sucesso!"
              });
            }
          });
        }
      }
    }
  },
};
</script>